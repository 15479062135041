import React,{useContext} from 'react'
import './style.scss'
import NaviContext from '../../context/NaviContext'

import { Link } from 'gatsby'

function Lang(props) {
	const naviContext = useContext(NaviContext)
	
	let slug = ""
	if(typeof(naviContext.locationPathname) === 'string' && naviContext.locationPathname.includes("mewheel")) {
		slug = "/mewheel"
	}

  return (
		<>
			<div className='social-icons lang'>
				<div className='social-icon hint--rounded hint--bottom' data-hint='Polski'>
					<Link to={`${slug}/`}>PL</Link>
				</div>
				<div className='social-icon hint--rounded hint--bottom' data-hint='English'>
					<Link to={`${slug}/en`}>EN</Link>
				</div>
				<div className='social-icon hint--rounded hint--bottom' data-hint='Español'>
					<Link to={`${slug}/es`}>ES</Link>
				</div>
				<div className='social-icon hint--rounded hint--bottom' data-hint='Français'>
					<Link to={`${slug}/fr`}>FR</Link>
				</div>
				<div className='social-icon hint--rounded hint--bottom' data-hint='Deutsche'>
					<Link to={`${slug}/de`}>DE</Link>
				</div>
			</div>
		</>
  )
}

export default Lang