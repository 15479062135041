import React, { useContext, useState } from 'react'
import './style.scss'
import NaviContext from '../../../../context/NaviContext'

import NaviItem from '../NaviItem'
import { Transition } from 'react-transition-group'
import anime from 'animejs'
import { Link } from 'gatsby'

function NaviPrimary(props) {
	const naviContext = useContext(NaviContext)
	const [dropDown, setDropDown] = useState([])
	
		
	// hardcoded menu
	let menuHomepage = {
		1: "Mobilność i bezpieczeństwo",
		2: "Zobacz",
		3: "Aktualności",
		4: "Przedsprzedaż",
		5: "Kontakt"
	}
	let menuLanding = "Więcej"

	if(typeof(naviContext.locationPathname) === 'string') {

		if(naviContext.locationPathname.includes("en")) {
			menuHomepage = {
				1: "Mobility and safety",
				2: "See",
				3: "News",
				4: "Pre-order",
				5: "Contact details"
			}
			menuLanding = "Learn more"
		}
		if(naviContext.locationPathname.includes("es")) {
			menuHomepage = {
				1: "Movilidad y seguridad",
				2: "Ver",
				3: "Noticias",
				4: "Venta anticipada",
				5: "Contacto"
			}
			menuLanding = "Más"
		}
		if(naviContext.locationPathname.includes("fr")) {
			menuHomepage = {
				1: "Mobilité et sécurité",
				2: "Découvrez",
				3: "Actualités",
				4: "Précommande",
				5: "Contact"
			}
			menuLanding = "En savoir plus"
		}
		if(naviContext.locationPathname.includes("de")) {
			menuHomepage = {
				1: "Mobilität und Sicherheit",
				2: "Ansehen",
				3: "Aktuelles",
				4: "Vorverkauf",
				5: "Kontakt"
			}
			menuLanding = "Mehr"
		}
	}


	// Animating fade in/out
	const baseDuration = 250
	const fadeInLogo = element => {
		const links = element.querySelectorAll('.nav-item')
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [0, 1],
				translateY: [-10, 0],
				duration: baseDuration, 
				easing: 'easeInOutSine',
			})
			.add({
				targets: links,
				opacity: [0, 1],
				duration: baseDuration,
				easing: 'easeInOutSine',
				delay: anime.stagger(100)
			}, `-=${baseDuration}`)
	}
	const fadeOutLogo = element => {
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [1, 0],
				duration: baseDuration / 2,
				easing: 'linear'
			})
	}

	// Menu feed
	const menuNodes = props.wpgraphql?.wpNaviPrimary?.nodes[0]?.menuItems.nodes
	// Re-organized menu feed
	let usedNodes = [
		[]
	]

	function executeDropDown(e,lvl,id) {
		e.preventDefault()
		// Copy parents
		let newArr = [...dropDown]
		// Set currently open
		newArr[lvl] = id
		// Cut the already open subitems, if we're rolling back
		newArr.length = lvl + 1
		// Put into state
		setDropDown(newArr)
	}

	function executeBlur(e) {
		if ( !e.currentTarget.contains( e.relatedTarget ) ) {
			setDropDown([])
    }
	}

	// Mark navi items that have children
	function markParent(id, array) {
		for (var key in array) {
			if(array.hasOwnProperty(key)) {
				array[key].map((item) => {
					if(item.id === id) {
						return item['itHasChildren'] = id
					}
				})
			}
		}
	}

	// Organize all menu items into arrays of parents
	function organizeMenuNodes(item) {
		const parent = item.parentId

		if(parent) {
			// Mark navi item that have children
			markParent(parent, usedNodes)
			// Has parent that already has its array, add to it
			if(usedNodes[parent]) {
				usedNodes[parent].push(item)
			// Has parent that doesn't have its array, create array for said parent, put it there
			} else {
				usedNodes[parent] = [item]
			}
		// No parents (First level)
		} else {
			usedNodes[0].push(item)
		}

		return item
	}

	const menuNodesMap = menuNodes?.map(organizeMenuNodes)

	function menuServe(naviNodes, key, lvl) {
		let result = []
		lvl++
		
		result.push(naviNodes[key].map((item) => {
			
				return [(
					<NaviItem
						key={item.id}
						{ ...item }
						dropDownClickHandle={item.itHasChildren ? (e) => executeDropDown(e,lvl,item.id) : () => setDropDown([])}
					>
						{item.itHasChildren && dropDown[lvl] === item.id ?
						<Transition
							in={naviContext.isActive ? true :	false}
							timeout={baseDuration}
							appear={true}
							onEntering={fadeInLogo}
							onExiting={fadeOutLogo}
							mountOnEnter
							unmountOnExit
						>
							<div className={`sub-nav-items level-${lvl}`}>
								{menuServe(naviNodes, item.itHasChildren, lvl)}
							</div>
						</Transition>
						: null}
					</NaviItem>
				)]
		}))

		if(props.wordpressPage?.slug !== 'home') 
			return (
				<>
						<Link to='#featured-items'><p>{menuHomepage[1]}</p></Link>
						<Link to='#video'><p>{menuHomepage[2]}</p></Link>
						<Link to='#aktualnosci'><p>{menuHomepage[3]}</p></Link>
						<Link to='#przedsprzedaz'><p>{menuHomepage[4]}</p></Link>
						<Link to='#kontakt'><p>{menuHomepage[5]}</p></Link>
				</>
			)
		else
			return <Link to={`/mewheel${naviContext.locationPathname}`}><p>{menuLanding}</p></Link>
			
	}

  return (
		<nav className='navi navi-primary'>
			<div
				className='navi-items'
				onBlur={(e) => executeBlur(e)}
			>
				{menuServe(usedNodes, 0, 0)}
			</div>
		</nav>
  )
}

export default NaviPrimary